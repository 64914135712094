import debounce from "lodash/debounce";

const nav = document.querySelector<HTMLElement>("nav")!;
const links = Array.from(nav.getElementsByTagName("a"));
const accent = nav.querySelector<HTMLElement>(".nav-accent")!;

const removeTrailingSlash = (s: string) => s.replace(/\/$/, "");

const areSameHref = (href1: string, href2: string) =>
  removeTrailingSlash(href1) === removeTrailingSlash(href2);

const prev = links.find((link) => areSameHref(link.href, document.referrer));
const curr = links.find((link) => areSameHref(link.href, window.location.href));

const isRefresh =
  (
    window.performance?.getEntriesByType(
      "navigation"
    )[0] as PerformanceNavigationTiming
  )?.type === "reload";

const isNavigatingBetweenPages =
  !isRefresh &&
  !!prev &&
  document.referrer.startsWith(window.location.origin) &&
  document.referrer !== window.location.href;

let hasShownNavigationTransition = false;

function setNavAccentPosition() {
  if (window.outerWidth <= 700) {
    hasShownNavigationTransition = true; // No transition in mobile layout
    return;
  }

  accent.style.transition = "none";

  // When navigating between pages for the first time, slide the nav accent from the previous item to the current one
  if (isNavigatingBetweenPages && !hasShownNavigationTransition) {
    // Place the nav accent underneath the previous nav item
    accent.style.width = prev.offsetWidth + "px";
    accent.style.left = prev.offsetLeft + "px";

    // Enable transitions so it slides over to the current nav item
    accent.style.transition = "";
  }

  if (curr) {
    // Set nav position to the current nav item
    accent.style.width = curr.offsetWidth + "px";
    accent.style.left = curr.offsetLeft + "px";
  }

  // Next time this is called on the current page, don't slide again
  hasShownNavigationTransition = true;
}

window.addEventListener("resize", debounce(setNavAccentPosition, 250));
window.addEventListener("load", setNavAccentPosition);

// When clicking the current nav link, don't do anything
links.forEach((a) =>
  a.addEventListener("click", (event) => {
    if (a.href === window.location.href) {
      event.preventDefault();
    }
  })
);

export {};
